import axios from 'axios';

async function isAuth() {

	let token = localStorage.getItem("token");

	if( !token ) {
		return 'unlogged';
	}

	const headers = { 
      'Content-Type': 'application/x-www-form-urlencoded',
      "X-Api-Token": localStorage.getItem("token")
    };

    const a = await (axios.post('https://bolaoitubers.online/api/check-login', {
      token: token
    },{ headers })
    .then(function (response) {
       	return 'logged';    
    })
    .catch(function (error) {
    	return 'unlogged'; 	
    }));

    return a;    
}

export default isAuth;

